<app-printable class="print-only"></app-printable>
<div class="no-print">
  <mat-toolbar color="primary">
    <span class="header-title">Allan Galarza</span>
  </mat-toolbar>
  <div class="container">
    <div class="flex flex-col md:flex-row gap-2">
      <div class="flex flex-col gap-2 md:basis-1/4">
        <mat-card appearance="raised" class="sidebar">
          <mat-card-content>
            <h2>Contact</h2>
            @for (link of profile.onlineProfiles; track link) {
            <p>
              <fa-icon [icon]="link.icon"></fa-icon>&nbsp;<a
                [href]="link.url">{{link.title}}</a>
            </p>
            }
            <h2>Languages</h2>
            @for (language of profile.languages; track language) {
            <p>
              {{language.name}} ({{language.level}})
            </p>
            }
            <h2>Interests</h2>
            @for (interest of profile.interests; track interest) {
            <p>
              {{interest}}
            </p>
            }
          </mat-card-content>
        </mat-card>
      </div>
      <div class="flex flex-col gap-2 md:basis-3/4">
        <div>
          <h2>About Me</h2>
          <mat-card appearance="raised">
            <mat-card-content [innerHTML]="profile.aboutMe">
            </mat-card-content>
          </mat-card>
        </div>
        <div>
          <h2>Skills & Tools</h2>
          <mat-chip-listbox multiple="true">
            @for(skillType of skillTypes; track skillType){
            <mat-chip-option [selected]="isSelected(skillType)"
              (click)="toggleSelection(skillType)" class="skill-filter-chip">
              {{skillType}}
            </mat-chip-option>
            }
          </mat-chip-listbox>
          <mat-card appearance="raised">
            <mat-card-content>
              <mat-chip-set aria-label="Skills" role="list">
                @for (skill of selectedSkills; track skill) {
                <mat-chip role="listitem">
                  @if (skill.icon) {
                  <app-skill-icon [tooltip]="false" class="skill-icon"
                    [skill]="skill.icon"></app-skill-icon>
                  }&nbsp;{{skill.name}}
                </mat-chip>
                }
                @empty {
                <em>Click on the filters to display relevant skills.</em>
                }
              </mat-chip-set>
            </mat-card-content>
          </mat-card>

        </div>
        <div>
          <app-work-experience [entries]="profile.workExperience">
          </app-work-experience>
        </div>
        <div>
          <app-projects [projects]="profile.projects"></app-projects>
        </div>
        <app-education [entries]="profile.education"></app-education>
      </div>
    </div>
  </div>
</div>