<div class="content">
  <h1 class="text-center text-5xl">Allan Galarza</h1>
  <p [innerHTML]="profile.aboutMe"></p>
  <div>
    <div class="flex flex-row place-content-evenly">
      <div>
        <fa-icon [icon]="faMapPin"></fa-icon>
        {{profile.location}}
      </div>
      @for (link of profile.onlineProfiles; track link) {

      @if (link.printable) {
      <div>
        <fa-icon [icon]="link.icon"></fa-icon>&nbsp;<a>{{link.title}}</a>
      </div>
      }
      }
      <div>
        <fa-icon [icon]="faGlobe"></fa-icon>
        galarzaa.com
      </div>
    </div>
  </div>
  <h2
    class="relative after:content-[''] after:block after:border-black after:border-2 after:mt-2">
    Skills & Tools</h2>
  <div class="grid grid-cols-4 justify-center">
    @for (skill of profile.skills; track skill) {
    <div class="text-center text-xs">
      @if (skill.icon != null) {
      <app-skill-icon [skill]="skill.icon"></app-skill-icon>&nbsp;
      }
      <span>{{skill.name}}</span>
    </div>
    }
  </div>
  <h2>Work Experience</h2>
  <div>
    @for (job of profile.workExperience; track job) {
    @if (job.printable !== false) {
    <div class="no-break">
      <div
        class="flex flex-row place-content-between items-center border-2 border-black py-1 px-3">
        <strong>{{job.company}}</strong>
        @if (job.end === undefined) {
        <span class="text-xs">
          {{job.start | date: 'MMMM y'}} — Present
        </span>
        }
        @if (job.end !== undefined) {
        <span class="text-xs">
          {{job.start | date: 'MMMM y'}} —
          {{job.end | date: 'MMMM y'}}
        </span>
        }
      </div>
      <div class="ms-5">
        <span [innerHTML]="job.description"></span>
        @if (job.items?.length != 0) {
        <ul class="list-disc list-inside ps-2 pt-2">
          @for (item of job.items; track item) {
          <li [innerHtml]="item">
          </li>
          }
        </ul>º
        }
        @if (job.skills != undefined) {
        <div
          class="job-skills-container flex flex-row items-center flex-wrap justify-center my-3">
          @for (skill of job.skills; track skill) {
          <span
            class="flex items-center text-xs first:border-0 first:ml-0 first:pl-0 border-l-2 border-black ml-1 pl-1">
            @if (skill != null) {
            <app-skill-icon [skill]="skill"></app-skill-icon>
            }&nbsp;{{skill.text}}
          </span>
          }
        </div>
        }
      </div>
    </div>
    }
    }
  </div>
  <h2>Projects</h2>
  <div>
    @for (project of profile.projects; track project) {
    @if(project.printable !== false){
    <div class="no-break">
      <div
        class="flex flex-row place-content-between items-center  border-2 border-black py-1 px-3">
        <strong>{{project.name}}</strong>
        @if (project.end === undefined) {
        <span>
          {{project.start | date: 'MMMM y'}} — Present
        </span>
        }
        @if (project.end !== undefined) {
        <span>
          {{project.start | date: 'MMMM y'}} —
          {{project.end | date: 'MMMM y'}}
        </span>
        }
      </div>
      <div class="ms-5">
        <span [innerHTML]="project.description"></span>
        @if (project.items?.length != 0) {
        <ul class="list-disc list-inside ps-2 pt-2">
          @for (item of project.items; track item) {
          <li [innerHtml]="item"></li>
          }
        </ul>
        }
        @if (project.skills != undefined) {
        <div
          class="job-skills-container flex flex-row items-center flex-wrap justify-center my-3">
          @for (skill of project.skills; track skill) {
          <span
            class="flex items-center text-xs  first:border-0 first:ml-0 first:pl-0 border-l-2 border-black ml-1 pl-1">
            @if (skill != null) {
            <app-skill-icon [skill]="skill"></app-skill-icon>
            }&nbsp;{{skill.text}}
          </span>
          }
        </div>
        }
      </div>
    </div>
    }
    }
  </div>
</div>