<h2>Education</h2>
<mat-accordion>
  @for (school of entries; track school) {
  <mat-expansion-panel hideToggle [hideToggle]="!(isXSmall$ | async)">
    <mat-expansion-panel-header class="title-date">
      <mat-panel-title>
        {{school.name}}
      </mat-panel-title>
      <mat-panel-description>
        <span class="hidden md:inline">
          @if (school.end === undefined) {
          <span>
            {{school.start | date: (isLarge$ | async) ? 'MMMM y' : 'MMM
            y'}} — Present
          </span>
          }
          @if (school.end !== undefined) {
          <span>
            {{school.start | date: (isLarge$ | async) ? 'MMMM y' : 'MMM
            y'}} —
            {{school.end | date: (isLarge$ | async) ? 'MMMM y' : 'MMM
            y'}}
          </span>
          }
        </span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="flex flex-row space-y-0 items-start">
      <div class="grow">
        <p>{{school.title}}<br>
          {{school.level}}<br>
          <span class="md:hidden date-subtitle">
            @if (school.end === undefined) {
            <span>
              {{school.start | date: 'MMMM y'}} — Present
            </span>
            }
            @if (school.end !== undefined) {
            <span>
              {{school.start | date: 'MMMM y'}} —
              {{school.end | date: 'MMMM y'}}
            </span>
            }
          </span>
          @if (school.skills != undefined) {
        <p class="school-skills pt-2">
          @for (skill of school.skills; track skill) {
          <app-skill-icon [skill]="skill"></app-skill-icon>
          }
        </p>
        }
      </div>
      <div class="grow max-w-[20%] sm:max-w-[15%] 2xl:max-w-[10%]">
        @if (school.logo != undefined) {
        <img class="company-logo" src="./assets/img/{{school.logo}}">
        }
      </div>
    </div>
  </mat-expansion-panel>
  }
</mat-accordion>